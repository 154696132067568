import React from 'react'

import './drawerToggleButton.css'

const drawerToggleButton = (props) => (
  <button className="toggle-button" onClick={props.click}>
    <span className="menu-line" />
    <span className="menu-line" />
    <span className="menu-line" />
  </button>
)

export default drawerToggleButton
