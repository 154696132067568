import React from 'react'
import ScrollUpButton from 'react-scroll-up-button'

import './footer.css'

const Footer = (props) => (
  <div>
    <ScrollUpButton />
    <footer>
      <div
        className="container"
        style={{
          maxWidth: '960px',
        }}
      >
        <div className="row">
          <div className="six columns">
            <p>
              <span>&copy;</span>
              {` ${new Date().getFullYear()} ${props.siteTitle}`}
            </p>
            <p>
              Website By{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://daffodil-marketing.com/services/website-design"
              >
                Daffodil Marketing
              </a>
            </p>
          </div>
          <div className="six columns socialMedia">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/pages/Nova-Electronics/164657393557667"
              className="socialMediaIconFacebook"
            />
            {/* <a href="#" className="socialMediaIconTwitter">
              {' '}
            </a> */}
          </div>
        </div>
      </div>
    </footer>
  </div>
)

export default Footer
