import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "../assets/normalise.css"
import "../assets/skeleton.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "animate.css/animate.min.css"
import "./layout.css"

export default function Layout({ children }) {
  const {
    site: {
      siteMetadata: { title, siteNavigation },
    },
  } = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            siteNavigation {
              location
              title
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Helmet
        title={title}
        meta={[
          { name: "description", content: "Sample" },
          { name: "keywords", content: "sample, something" },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <Header siteTitle={title} siteNavigation={siteNavigation} />
      <div>{children}</div>
      <Footer siteTitle={title} />
    </>
  )
}
